import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { sfAuthFeature } from './store/sf-auth.store';
import * as SfDictionaryEffects from './store/sf-dictionary.effects';
import * as SfAuthEffects from './store/sf-auth.effects';
import { SfWebMessagesService } from './services/sf-web-messages.service';
import * as dictionaryStore from '../../../dictionary/store/dictionary.reducer';
import { DictionaryService } from '../../../dictionary/services/dictionary.service';
import * as coreStore from '../../../core/store/core.reducer';
import { ConfigService } from '../../../core/services/config.service';
import { SidedrawerService } from '../../../sidedrawer/services/sidedrawer.service';
import { MatDialogModule } from '@angular/material/dialog';
import { SdCacheModule } from '../../../shared/sd-cache/sd-cache.module';
import { RecordsService } from '../../../records/services/records.service';
import { HomeService } from '../../../home/services/home.service';
import { AuthGuard } from '../../../auth/guards/auth.guard';
import { PaginatorService } from '../../../core/services/paginator.service';
import { FilesService } from '../../../files/services/files.service';
import { RelatedRecordsService } from '../../../related-records/services/related-records.service';
import {
  featureName as sideDrawerFeatureName,
  sidedrawerReducer,
} from '../../../sidedrawer/store/sidedrawer.reducer';
import {
  featureName as networksFeatureName,
  networksReducer,
} from '../../../networks/store/networks.reducer';
import { queueFeature } from '../../../queue/store/queue.reducer';
import {
  featureName as signaturesFeatureName,
  signatureReducer,
} from '../../../signature/store/signature.reducer';
import {
  featureName as timelineFeatureName,
  timelineReducer,
} from '../../../timeline/store/timeline.reducer';
import {
  featureName as sdBackDropFeatureName,
  sdBackdropReducer,
} from '../../../home/shared/sd-backdrop/store/sd-backdrop.reducer';
import * as QueueEffects from '../../../queue/store/queue.effects';
import * as timelineEffects from '../../../timeline/store/timeline.effects';
import { NetworksEffects } from '../../../networks/store/networks.effects';
import { NetworksCrudEffects } from '../../../networks/store/networks-crud/networks-crud.effect';
import { SidedrawerEffects } from '../../../sidedrawer/store/sidedrawer.effects';
import { SignatureEffects } from '../../../signature/store/signature.effects';
import { RemindersService } from '../../../reminders/services/reminders.service';
import { SignatureService } from '../../../signature/services/signature.service';
import { PlansService } from '../../../plans/services/plans.service';
import { IntegrationsService } from '../../../integrations/services/integrations.service';
import { NetworkService } from '../../../networks/services/network.service';
import { DragDropOverlaySnackBarContainer } from '../../../files/directives/drag-and-drop/directive/drag-and-drop/drag-drop-overlay-container';
import { RemindersModule } from '../../../reminders/reminders.module';
import { filingCabinetFeature } from '../../../filing-cabinet/store/filing-cabinet.store';
import * as FilingCabinetEffects from '../../../filing-cabinet/store/filing-cabinet.effect';
import { FilingCabinetService } from '../../../filing-cabinet/services/filing-cabinet.service';
import { SfCoreEffects } from './store/sf-core.effects';
import { AccountService } from '../../../account/services/account.service';
import {
  accountReducer,
  featureName as accountFeature,
} from '../../../account/store/account.reducer';
import { SearchModule } from '../../../search/search.module';
import { reportsFeature } from '../../../reports/store/reports.store';
import * as ReportsEffects from '../../../reports/store/reports.effects';
import { ReportsService } from '../../../reports/services/reports.service';
import { networkListFeature } from '../../../networks/store/network-list.store';
import { networkSearchFeature } from '../../../networks/store/network-search.store';
import { networkPermanentFeature } from '../../../networks/store/network-permanent.store';
import * as NetworkListEffects from '../../../networks/store/network-list.effects';
import * as NetworkSearchEffects from '../../../networks/store/network-search.effects';
import * as NetworkPermanentEffects from '../../../networks/store/network-permanent.effects';
import { SdFastUploadDialogSignalStore } from 'src/app/records/shared/sd-fast-upload-a11y/sd-fast-upload-dialog-signal.store';
import {
  recordsReducer,
  featureName as recordsFeatureName,
} from '../../../records/store/records.reducer';
import { RecordsListEffects } from 'src/app/records/store/records-list.effects';
import { RecordItemEffects } from 'src/app/records/store/record-item.effects';
import { SdCopyMoveDialogSignalStore } from 'src/app/files/shared/sd-copy-move-a11y/sd-copy-move-dialog-signal.store';
@NgModule({
  imports: [
    StoreModule.forFeature(sfAuthFeature),
    StoreModule.forFeature(
      dictionaryStore.featureName,
      dictionaryStore.dictionaryReducer
    ),
    StoreModule.forFeature(coreStore.featureName, coreStore.coreReducer),
    StoreModule.forFeature(sideDrawerFeatureName, sidedrawerReducer),
    StoreModule.forFeature(networksFeatureName, networksReducer),
    StoreModule.forFeature(networkListFeature),
    StoreModule.forFeature(networkSearchFeature),
    StoreModule.forFeature(networkPermanentFeature),
    StoreModule.forFeature(queueFeature),
    StoreModule.forFeature(signaturesFeatureName, signatureReducer),
    StoreModule.forFeature(timelineFeatureName, timelineReducer),
    StoreModule.forFeature(sdBackDropFeatureName, sdBackdropReducer),
    StoreModule.forFeature(filingCabinetFeature),
    StoreModule.forFeature(accountFeature, accountReducer),
    StoreModule.forFeature(reportsFeature),
    StoreModule.forFeature(recordsFeatureName, recordsReducer),
    EffectsModule.forFeature([
      SfDictionaryEffects,
      SfAuthEffects,
      SfCoreEffects,
      QueueEffects,
      timelineEffects,
      NetworksEffects,
      NetworksCrudEffects,
      SidedrawerEffects,
      SignatureEffects,
      FilingCabinetEffects,
      ReportsEffects,
      NetworkListEffects,
      NetworkSearchEffects,
      NetworkPermanentEffects,
      RecordsListEffects,
      RecordItemEffects,
    ]),
    SdCacheModule,
    MatDialogModule,
    RemindersModule,
    SearchModule,
  ],
  providers: [
    SfWebMessagesService,
    AccountService,
    DictionaryService,
    ConfigService,
    SidedrawerService,
    RecordsService,
    HomeService,
    AuthGuard,
    PaginatorService,
    FilesService,
    RelatedRecordsService,
    RemindersService,
    SidedrawerService,
    SignatureService,
    PlansService,
    IntegrationsService,
    NetworkService,
    DragDropOverlaySnackBarContainer,
    FilingCabinetService,
    ReportsService,
    SdFastUploadDialogSignalStore,
    SdCopyMoveDialogSignalStore,
  ],
})
export class SfCoreModule {}
